<script setup lang="ts">
import { TypeBaseButton, ViewBaseButton } from './ABaseButton.const';

import type { RouteLocationRaw } from 'vue-router';

interface Props {
  to?: string | RouteLocationRaw;
  external?: boolean;
  block?: boolean;
  back?: boolean;
  isAuth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  type?: TypeBaseButton;
  view?: ViewBaseButton;
  icon?: string | any;
}

const props = withDefaults(defineProps<Props>(), {
  to: '',
  block: false,
  back: false,
  isAuth: false,
  external: false,
  disabled: false,
  loading: false,
  type: TypeBaseButton.Large,
  view: ViewBaseButton.Primary,
  icon: undefined,
});

const { block, disabled, loading, type, view, icon, isAuth } = toRefs(props);

const router = useRouter();

const emit = defineEmits(['click']);

const handleClick = (event: MouseEvent) => {
  if (!props.loading && !props.disabled) {
    emit('click', event);
  }
};

const goBack = async () => {
  if (isAuth.value) {
    await navigateTo(DEFAULT_ROOT)
  }
  if (window.history.length > 1) {
    router.back();
  } else {
    router.push('/');
  }
};

const buttonIs = computed(() => {
  if (props.to) {
    return defineNuxtLink({});
  }

  return 'button';
});

const buttonClass = computed(() => [
  {
    '-block': block.value,
    '-disabled': disabled.value,
    '-loading': loading.value,
  },
  `-${view.value}`,
  `-${type.value}`,
]);
const onClick = computed(() => (props.back ? goBack : handleClick));

const target = computed(() => (props.external ? '_blank' : undefined))
</script>

<template>
  <component
    v-bind="!props.to && { type: 'button' }"
    :is="buttonIs"
    :to="props.to"
    :target="target"
    class="a-base-button"
    :class="buttonClass"
    :disabled="loading || disabled"
    @click="onClick"
  >
    <span class="a-base-button-row">
      <slot />
      <component :is="icon" v-if="icon" class="button-icon" />
    </span>
  </component>
</template>

<style lang="scss">
.a-base-button {
  display: inline-block;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: 0.25s ease-in-out;
  &.-block {
    width: 100%;
  }
  &.-loading,
  &.-disabled {
    cursor: not-allowed;
  }
  &.-square {
    height: 54px;
    width: 54px;
    min-width: none;
    border-radius: 12px;
  }
  &.-round {
    height: 52px;
    width: 52px;
    border-radius: 50%;
  }

  &.-large {
    height: $button-height-large;
    min-width: $button-height-large;
    border-radius: 10px;
    padding: 0 16px;
    font-size: 16px;
  }
  &.-medium {
    height: $button-height-medium;
    min-width: $button-height-medium;
    border-radius: 14px;
  }
  &.-small {
    height: $button-height-small;
    min-width: $button-height-small;
    letter-spacing: 0.25px;
    padding: 0 12px;
  }
  &.-primary {
    background-color: $button-bg-primary;
    color: $button-text-primary;
    &:hover {
      background-color: $button-bg-primary-hover;
    }
    &.-loading,
    &.-disabled {
      background-color: $button-bg-primary-disabled;
      color: $button-text-primary-disabled;
    }
  }
  &.-secondary {
    background: $button-bg-secondary;
    color: $button-text-secondary;
    &:hover {
      background-color: $button-bg-secondary-hover;
    }
    &.-disabled {
      background-color: $button-bg-secondary-disabled;
      color: $button-text-secondary-disabled;
    }
  }
  &.-tertiary {
    background: $button-bg-tertiary;
    color: $button-text-tertiary;
    &:hover {
      background-color: $button-bg-tertiary-hover;
    }
    &.-disabled {
      background-color: $button-bg-tertiary-disabled;
      color: $button-text-tertiary-disabled;
    }
  }
  &.-white {
    background-color: $bg-primary;
    color: $text-primary;
    box-shadow: 0px 0px 20px 0px rgba(10, 10, 10, 0.2);
    &.-disabled {
      color: $text-secondary;
    }
  }
  &.-red {
    background-color: $bg-negative;
    color: $text-negative;
  }
}
.a-base-button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 8px;
}
</style>
